
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useApiOpenModal from '@/compositions/services/useApiOpenModal'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  setup() {
    const { openDeleteVerifyModal } = useApiOpenModal()

    return {
      openDeleteVerifyModal,
    }
  },
})
