
import {
  defineComponent, ref, computed
} from 'vue'
import ServicesApiVerifyTable from '@/components/pages/services/api/verify/ServicesApiVerifyTable.vue'
import ServicesApiVerifyFilter from '@/components/pages/services/api/verify/ServicesApiVerifyFilter.vue'
import ServicesApiVerifyEmpty from '@/components/pages/services/api/verify/ServicesApiVerifyEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServiceApiVerify } from '@/definitions/services/api/types'

export default defineComponent({
  components: {
    ServicesApiVerifyTable,
    ServicesApiVerifyFilter,
    ServicesApiVerifyEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const selected = ref<ServiceApiVerify[]>([])

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Phone', value: 'phone' },
      { text: 'Brand', value: 'brand' },
      { text: 'API key label', value: 'label-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])

    const tableItems = getTableData('apiVerify') as ServiceApiVerify[]

    const tableItemsFiltered = computed(() => {
      if (!search.value) return tableItems
      return tableItems.filter((l) => l.brand.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      search,
      selected,
      tableHeaders,
      tableItemsFiltered,
      isEmptyMode,
    }
  },
})
