import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_api_verify_filter = _resolveComponent("services-api-verify-filter")!
  const _component_services_api_verify_table = _resolveComponent("services-api-verify-table")!
  const _component_services_api_verify_empty = _resolveComponent("services-api-verify-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_services_api_verify_filter, {
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            selected: _ctx.selected
          }, null, 8, ["search", "selected"]),
          _createVNode(_component_services_api_verify_table, {
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
            headers: _ctx.tableHeaders,
            items: _ctx.tableItemsFiltered,
            "items-name": "logs",
            "show-selection": ""
          }, null, 8, ["modelValue", "headers", "items"])
        ], 64))
      : (_openBlock(), _createBlock(_component_services_api_verify_empty, { key: 1 }))
  ]))
}