
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import ServicesApiVerifyDropdown from '@/components/pages/services/api/verify/ServicesApiVerifyDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServiceApiVerify } from '@/definitions/services/api/types'

export default defineComponent({
  components: {
    TmTable,
    TmStatus,
    TmBadge,
    ServicesApiVerifyDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ServiceApiVerify[]>,
      required: true,
    },
  },
})
